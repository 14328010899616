<template>
  <div class="user-role-form" v-loading="loading">
    <div class="role-list">
      <div class="role-item" v-for="item in dataList" :key="item.id">
        <div class="org-name" v-if="item.orgId">{{item.orgName || ''}}</div>
        <div class="role-name">{{item.roleName || '无职务'}}</div>
        <div class="btns">
          <div class="btn-item" @click="del(item)">删除</div>
        </div>
      </div>
      <div class="role-item" @click="openDialog = true">
        + 添加
      </div>
    </div>
    <fm-modal
      :mask-closable="false"
      :value="openDialog"
      width="600"
      v-if="openDialog"
      theme="mh-withe"
      @cancel="openDialog = false">
      <org-tree :is-select="true" @change="change"></org-tree>
    </fm-modal>
  </div>
</template>

<script>
import {
  userOrgRoleRequest
} from '@/api'
import OrgTree from '@/views/org/org/orgTree'

export default {
  components: {
    OrgTree
  },
  props: {
    userId: { type: Number }
  },
  watch: {
    userId: {
      handler () {
        this.loadData()
      },
      immediate: true,
      deep: true
    }
  },
  computed: {
    formParms () {
      return [{
        type: 'select',
        selectDatas: this.$store.getters.roleList.filter(v => v.data.roleType === 'role'),
        label: '职务',
        key: 'roleId',
        check: {
          required: true
        }
      },
      {
        type: 'select',
        selectDatas: this.$store.getters.orgList,
        label: '组织架构',
        key: 'orgId'
      }]
    }
  },
  data () {
    return {
      dataList: [],
      openDialog: false,
      loading: false
    }
  },
  methods: {
    async change (data) {
      if (data.id === 0) {
        if (this.dataList.find(v => v.roleId === null && v.orgId === data.orgId)) {
          this.$notify({
            title: '系统提示',
            message: data.orgName + data.name + '已存在',
            type: 'info'
          })
          return
        }
      } else {
        if (this.dataList.find(v => v.roleId === data.id && v.orgId === data.orgId)) {
          this.$notify({
            title: '系统提示',
            message: data.orgName + data.name + '已存在',
            type: 'info'
          })
          return
        }
      }
      const result = await this.$dialog.confirm({title: '系统提示', content: '确定为该用户添加 ' + data.orgName + ' ' + data.name + ' 吗?'})
      if (result) {
        await userOrgRoleRequest.add({
          userId: this.userId,
          roleId: data.id,
          orgId: data.orgId
        })
        this.openDialog = false
        this.loadData()
        this.$emit('reload')
      }
    },
    async del (item) {
      const result = await this.$dialog.confirm({title: '系统提示', content: '确定删除该职务吗?'})
      if (result) {
        userOrgRoleRequest.del(item.id).then(() => {
          this.$notify({
            title: '系统提示',
            message: '职务已删除',
            type: 'info'
          })
          this.loadData()
          this.$emit('reload')
        })
      }
    },
    async loadData () {
      if (this.userId) {
        let datas = await userOrgRoleRequest.get({
          userId: this.userId
        })
        this.dataList = datas.filter(v => v.orgId)
      } else {
        this.dataList = []
      }
    }
  }
}
</script>

<style lang="less" scoped>
.user-role-form {
  width: 100%;
  .role-list {
    display: flex;
    flex-wrap: wrap;
  }
  .role-item {
    min-width: 100px;
    padding: 10px 10px 20px;
    cursor: pointer;
    position: relative;
    margin: 0 20px 20px 0;
    height: 80px;
    border-radius: 5px;
    box-shadow: 1px 1px 2px  rgba(0,0,0,0.1);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
  }
  .role-item:hover {
    box-shadow: 2px 2px 4px  rgba(0,0,0,0.2);
    .btns {
      display: flex;
    }
  }
  .btns {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    display: none;
    border-top: 1px solid rgba(0,0,0,0.05);
    .btn-item {
      flex: 1;
      line-height: 22px;
      text-align: center;
      font-size: 12px;
    }
  }
}
</style>