<template>
  <div v-if="display">
    <fm-title title-text="职务信息"></fm-title>
    <div style="padding: 0 20px;" class="" v-if="data && data.userId">
      <UserOrgRoleForm @reload="loadData" :user-id="data.userId" />
    </div>
  </div>
</template>

<script>
import UserOrgRoleForm from '@/views/sys/user/orgRoleForm.vue'

import {
  workerRequest
} from '../../../../api'

export default {
  props: {
    isReal: {
      type: Boolean,
      default: false
    },
    display: {
      type: Boolean,
      default: true
    },
    workerId: {
      type: Number
    },
    isAbsolut: {
      type: Boolean,
      default: true
    }
  },
  components: {
    UserOrgRoleForm
  },
  watch: {
    completeness: {
      handler (data) {
        this.$emit('completeness', data)
      },
      immediate: true,
      deep: true
    }
  },
  data () {
    return {
      formData: {},
      formCheck: true,
      isChange: false,
      data: null
    }
  },
  created () {
    this.loadData()
  },
  computed: {
    completeness () {
      if (!this.data) {
        return 0
      }
      console.log(this.data)
      return this.data.userOrgRoleList.length > 0 ? 1 : 0
    }
  },
  methods: {
    async loadData () {
      if (this.workerId) {
        let data = await workerRequest.get({
          workerId: this.workerId
        })
        if (data.length !== 1) {
          this.$notice.info({
            title: '系统提示',
            desc: '数据异常，请联系管理员'
          })
        } else {
          this.data = data[0]
        }
      }
    },
    testEdited () {
      return this.isChange
    },
    async save () {
      return true
    },
    async submit () {
      return true
    }
  }
}
</script>
