<template>
  <div class="content worker-data">
    <fm-form :label-alone="false" label-align="right">
      <fm-form-item v-if="updateKeys.includes('name')" label="姓名">
        <fm-input-new v-model="formData.name" placeholder="姓名"/>
      </fm-form-item>
      <fm-form-item v-if="updateKeys.includes('sex')" label="性别">
        <fm-radio-group v-model="formData.sex">
          <fm-radio value="男" label="男"></fm-radio>
          <fm-radio value="女" label="女"></fm-radio>
        </fm-radio-group>
      </fm-form-item>
      <fm-form-item v-if="updateKeys.includes('clan')" label="民族">
        <fm-select absolute filterable :clearable="true" v-model="formData.clan">
          <fm-option v-for="item in workerClanList" :key="item.key" :value="item.key" :label="item.label"></fm-option>
        </fm-select>
      </fm-form-item>
      <fm-form-item v-if="updateKeys.includes('politicalStatus')" label="政治面貌">
        <fm-select absolute filterable :clearable="true" v-model="formData.politicalStatus">
          <fm-option v-for="item in workerPoliticalStatusList" :key="item.key" :value="item.key" :label="item.label"></fm-option>
        </fm-select>
      </fm-form-item>
      <fm-form-item v-if="updateKeys.includes('idNo')" label="身份证号码">
        <fm-input-new  placeholder="身份证号码" v-model="formData.idNo"/>
      </fm-form-item>
      <fm-form-item v-if="updateKeys.includes('birth')" label="出生年月">
        <fm-date-picker
          absolute
          :value="formData.birth"
          @change="(v) => formData.birth = v  ? v.replace(/年|月/g, '-').replace('日', '') : ''"
          :clearable="true"
          placeholder="请选择"
          type="date"
          format="Y年M月D日"
        ></fm-date-picker>
      </fm-form-item>
      <fm-form-item v-if="updateKeys.includes('sf')" label="个人身份">
        <fm-select absolute filterable :clearable="true" v-model="formData.sf" >
          <fm-option v-for="item in workerIdentityList" :key="item.key" :value="item.key" :label="item.label"></fm-option>
        </fm-select>
      </fm-form-item>
      <fm-form-item v-if="updateKeys.includes('education')" label="学历">
        <fm-select absolute filterable :clearable="true" v-model="formData.education">
          <fm-option v-for="item in workerEducationList" :key="item.key" :value="item.key" :label="item.label"></fm-option>
        </fm-select>
      </fm-form-item>
      <fm-form-item v-if="updateKeys.includes('degree')" label="学位">
        <fm-select absolute filterable :clearable="true" v-model="formData.degree">
          <fm-option v-for="item in workerEduDegrees" :key="item.key" :value="item.key" :label="item.label"></fm-option>
        </fm-select>
      </fm-form-item>
      <fm-form-item v-if="updateKeys.includes('hometown')" label="籍贯">
        <fm-input-new placeholder="籍贯" v-model="formData.hometown"/>
      </fm-form-item>
      <fm-form-item v-if="updateKeys.includes('phone')" label="联系电话">
        <fm-input-new placeholder="联系电话" v-model="formData.phone"/>
      </fm-form-item>
      <fm-form-item v-if="updateKeys.includes('agreementCode')" label="是否特殊人才">
        <fm-select absolute filterable :clearable="true" v-model="formData.agreementCode">
          <fm-option v-for="item in ['是', '否']" :key="item" :value="item" :label="item"></fm-option>
        </fm-select>
      </fm-form-item>
      <fm-form-item v-if="updateKeys.includes('graduatedSchool')" label="是否港澳台及外籍人士">
        <fm-select absolute filterable :clearable="true" v-model="formData.graduatedSchool">
          <fm-option v-for="item in ['是', '否']" :key="item" :value="item" :label="item"></fm-option>
        </fm-select>
      </fm-form-item>
      <fm-form-item v-if="updateKeys.includes('code')" label="工号">
        <fm-input-new v-model="formData.code" placeholder="工号"/>
      </fm-form-item>
      <fm-form-item v-if="updateKeys.includes('orgName')" label="机构名称">
        <fm-input-new v-model="formData.orgName" placeholder="机构名称"/>
      </fm-form-item>
      <fm-form-item v-if="updateKeys.includes('zg')" label="机构名称2">
        <fm-input-new v-model="formData.zg" placeholder="机构名称2"/>
      </fm-form-item>
      <fm-form-item v-if="updateKeys.includes('positionId')" label="所属职务">
        <org-select absolute filterable 
          :clearable="true" v-model="formData.positionId">
        </org-select>
      </fm-form-item>
      <fm-form-item v-if="updateKeys.includes('positionIds')" label="兼职职务">
        <OrgMultipleSelect absolute filterable 
          :clearable="true" v-model="formData.positionIds">
        </OrgMultipleSelect>
      </fm-form-item>
      <!-- <fm-form-item v-if="updateKeys.includes('')" label="默认展示机构等级">
        <fm-select absolute filterable :clearable="true" v-model="formData.zhuanzhi">
          <fm-option v-for="item in orgTypeList.map(v => {return {key: 'orgTypeValue_' + v.data.id, label: v.data.name}})" :key="item.key" :value="item.key" :label="item.label"></fm-option>
        </fm-select>
      </fm-form-item> -->
      <fm-form-item v-if="updateKeys.includes('type')" label="是否在编">
        <fm-select absolute filterable :clearable="true" v-model="formData.type">
          <fm-option v-for="item in ['在编', '非在编']" :key="item" :value="item" :label="item"></fm-option>
        </fm-select>
      </fm-form-item>
      <fm-form-item v-if="updateKeys.includes('jobTypeC')" label="在岗状态">
        <fm-select absolute filterable :clearable="true" v-model="formData.jobTypeC">
          <fm-option v-for="item in workerZgStatus" :key="item.key" :value="item.key" :label="item.label"></fm-option>
        </fm-select>
      </fm-form-item>
      <fm-form-item v-if="updateKeys.includes('correctionDate')" label="参加工作时间">
        <fm-date-picker
          absolute
          :value="formData.correctionDate"
          @change="(v) => formData.correctionDate = v  ? v.replace(/年|月/g, '-').replace('日', '') : ''"
          :clearable="true"
          placeholder="请选择"
          type="date"
          format="Y年M月D日"
        ></fm-date-picker>
      </fm-form-item>
      <fm-form-item v-if="updateKeys.includes('entryDate')" label="进入本单位时间">
        <fm-date-picker
          absolute
          :value="formData.entryDate"
          @change="(v) => formData.entryDate = v ? v.replace(/年|月/g, '-').replace('日', '') : ''"
          :clearable="true"
          placeholder="请选择"
          type="date"
          format="Y年M月D日"
        ></fm-date-picker>
      </fm-form-item>
      <fm-form-item v-if="updateKeys.includes('jobType')" label="人员类别">
        <fm-select absolute filterable :clearable="true" v-model="formData.jobType">
          <fm-option v-for="item in workerJobTypes" :key="item.key" :value="item.key" :label="item.label"></fm-option>
        </fm-select>
      </fm-form-item>
      <fm-form-item v-if="updateKeys.includes('employmentForm')" label="入职方式">
        <fm-select absolute filterable :clearable="true" v-model="formData.employmentForm">
          <fm-option v-for="item in employmentForms" :key="item.key" :value="item.key" :label="item.label"></fm-option>
        </fm-select>
      </fm-form-item>
      <fm-form-item v-if="updateKeys.includes('discipline')" label="离职方式">
        <fm-select absolute filterable :clearable="true" v-model="formData.discipline">
          <fm-option v-for="item in workerResignList" :key="item.key" :value="item.key" :label="item.label"></fm-option>
        </fm-select>
      </fm-form-item>
      <fm-form-item v-if="updateKeys.includes('address')" label="是否专业技术人员">
        <fm-select absolute filterable :clearable="true" v-model="formData.address">
          <fm-option v-for="item in ['是', '否']" :key="item" :value="item" :label="item"></fm-option>
        </fm-select>
      </fm-form-item>
      <fm-form-item v-if="updateKeys.includes('shuangjiantiao')" label="专业技术人员专业名称">
        <fm-input-new v-model="formData.shuangjiantiao" placeholder="专业技术人员专业名称"/>
      </fm-form-item>
      <fm-form-item v-if="updateKeys.includes('email')" label="专业技术人员专业类别">
        <fm-select absolute filterable :clearable="true" v-model="formData.email">
          <fm-option v-for="item in zyjsryzylb" :key="item.key" :value="item.key" :label="item.label"></fm-option>
        </fm-select>
      </fm-form-item>
      <fm-form-item v-if="updateKeys.includes('jobTitle')" label="是否卫生专业技术人员">
        <fm-select absolute filterable :clearable="true" v-model="formData.jobTitle">
          <fm-option v-for="item in ['是', '否']" :key="item" :value="item" :label="item"></fm-option>
        </fm-select>
      </fm-form-item>
    </fm-form>
  </div>
</template>

<script>

import OrgSelect from '@/views/org/org/orgSelect'
import OrgMultipleSelect from '@/views/org/org/orgMultipleSelect'

export default {
  components: {
    OrgSelect,
    OrgMultipleSelect
  },
  props: {
    updateKeys: {
      type: Array
    }
  },
  data () {
    return {
      formData: {
        positionIds: [],
        positionId: null,
        entryDate: null,
        correctionDate: null,
        birth: null
      }
    }
  },
  computed: {
    workerJobTypes () {
      return this.$store.getters.selectDatas['worker_job_type']
    },
    employmentForms () {
      return this.$store.getters.selectDatas['employment_form']
    },
    zyjsryzylb () {
      return this.$store.getters.selectDatas['zyjsryzylb']
    },
    workerZgStatus () {
      return this.$store.getters.selectDatas['worker_zg_status']
    },
    workerEduDegrees () {
      return this.$store.getters.selectDatas['worker_edu_degree']
    },
    workerClanList () {
      return this.$store.getters.selectDatas['worker_clan_list']
    },
    workerPoliticalStatusList () {
      return this.$store.getters.selectDatas['worker_political_status_list']
    },
    workerIdentityList () {
      return this.$store.getters.selectDatas['worker_identity_list']
    },
    workerEducationList () {
      return this.$store.getters.selectDatas['worker_education_list']
    },
    workerResignList () {
      return this.$store.getters.selectDatas['worker_resign_list']
    }
  },
  methods: {
    getFormData () {
      if (!this.formData.entryDate) {
        this.formData.entryDate = null
      }
      if (!this.formData.correctionDate) {
        this.formData.correctionDate = null
      }
      return this.formData
    }
  }
}
</script>

<style scoped lang="less">
</style>
