<template>
  <div class="org-label" @click="clickIt">
    <i class="iconfont" :class="{'icon-a-zu1443': nodeType === 'org', 'icon-a-zujian215': nodeType === 'role',}"></i>
    <span @click.stop="chooseIt" :class="{'p_label': nodeType === 'role', 'g_label': nodeType === 'org'}">{{ (data.code || '') + ' ' + (data.name || '')}}</span>
    <fm-btn v-if="isSelect && data.orgId" @click="chooseIt" class="org-label-btn" style="margin-left: 1rem;" size="small">选择</fm-btn>
  </div>
</template>

<script>
export default {
  props: {
    data: {
      type: Object
    },
    isSelect: {
      type: Boolean
    },
    nodeVm: {
      type: Object
    }
  },
  computed: {
    nodeType () {
      return this.data.nodeType || 'org'
    }
  },
  methods: {
    clickIt () {
      this.nodeVm.openThis(true)
    },
    chooseIt () {
      if (this.nodeType === 'role') {
        this.$emit('change', this.data)
      } else {
        this.clickIt()
      }
    }
  }
}
</script>

<style lang="less" scoped>
.org-label {
  display: flex;
  align-items: center;
  cursor: pointer;
  border-radius: 4px;
  padding: 0 5px;
  height: 22px;
  .p_label {
    color: #F4628F;
  }
  i {
    margin-right: 0.5rem;
  }
  .icon-a-zujian215 {
    color: #F4628F;
  }
}
.org-label-btn {
  display: none;
}
.org-label:hover {
  .org-label-btn {
    display: inline-flex;
  }
}
</style>