import { render, staticRenderFns } from "./batchUpdate.vue?vue&type=template&id=661da3cb&scoped=true&"
import script from "./batchUpdate.vue?vue&type=script&lang=js&"
export * from "./batchUpdate.vue?vue&type=script&lang=js&"
import style0 from "./batchUpdate.vue?vue&type=style&index=0&id=661da3cb&prod&scoped=true&lang=less&"


/* normalize component */
import normalizer from "!../../../../../yiweb/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "661da3cb",
  null
  
)

export default component.exports