<template>
  <div class="org-form-dev">
    <a :class="wrapClass" class="org-form" href="javascript:;" @click="modalOrg = true">{{valueLabels.map(v => v.name).join(',')}}</a>
    <fm-modal v-model="modalOrg" :width="$store.getters.clientType === 'wap' ? '100%' : '800px'" theme="mh-blackt">
      <div class="choose-infos">
        <div class="choose-item" style="margin-right: 30px;">兼职职务:</div>
        <div class="choose-item" v-for="item in chooseLabels" :key="item.id">
          <div>{{(item.code || '') + ' ' + (item.name || '')}}</div>
          <a @click="del(item.id)" class="del-item" href="javascript:;">X</a>
        </div>
      </div>
      <div style="width:100%;height:65vh;">
        <org-tree :is-select="true" @change="change"></org-tree>
      </div>
      <div class=btm-c>
        <fm-btn style="margin-right:20px;" @click="submit">确定</fm-btn>
        <fm-btn @click="modalOrg = false">取消</fm-btn>
      </div>
    </fm-modal>
  </div>
</template>

<script>
import OrgTree from './orgTree'
import Vue from 'vue'

export default {
  name: 'AssetsTypeForm',
  components: {
    OrgTree
  },
  data () {
    return {
      modalOrg: false,
      onError: false,
      valueEdit: '',
    }
  },
  watch: {
    value: {
      handler () {
        this.valueEdit = this.value ? this.value.map(v => v) : []
      },
      immediate: true
    }
  },
  props: {
    value: [Array, String],
    disabled: {
      type: Boolean,
      default: false,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    clearable: {
      type: Boolean,
      default: false
    },
    placeholder: {
      type: String,
      default: ''
    }
  },
  model: {
    prop: 'value',
    event: 'change'
  },
  computed: {
    chooseLabels () {
      return this.$store.getters.positionList.filter(v => this.valueEdit.includes(v.data.id)).map(v => v.data)
    },
    valueLabels () {
      let datas = this.value || []
      return this.$store.getters.positionList.filter(v => datas.includes(v.data.id)).map(v => v.data)
    },
    wrapClass () {
      return {
        'verifier-error': this.onError
      }
    }
  },
  methods: {
    verifier () {
      const msg = this.$verifier.check(this.value, false, [])
      this.$emit('verifier', msg)
      this.onError = msg !== ''
      return msg
    },
    submit () {
      this.modalOrg = false
      this.$emit('change', this.valueEdit)
      Vue.nextTick(() => {
        this.verifier()
      })
    },
    del (item) {
      this.valueEdit = this.valueEdit.filter(v => v !== item)
    },
    change (data) {
      if (this.valueEdit.includes(data.id)) {
        this.valueEdit = this.valueEdit.filter(v => v !== data.id)
      } else {
        this.valueEdit.push(data.id)
      }
    }
  }
}
</script>

<style lang="less" scope>
.choose-infos {
  display: flex;
  flex-wrap: wrap;
  .choose-item {
    margin-right: 30px;
    display: flex;
    padding: 10px 0;
    .del-item {
      margin-left: 10px;
      width: 20px;
      height: 20px;
      line-height: 20px;
      border: 1px solid #F4628F;
      color: #F4628F;
      text-align: center;
      font-weight: 600;
      border-radius: 100%;
    }
  }
}
.btm-c {
  display: flex;
  justify-content: center;
}
.org-form {
  min-width: 50px;
  width: 100%;
  border-radius: 4px;
  transition: all 0.2s;
  display: inline-flex;
  box-sizing: border-box;
  padding: 0;
  border: 1px solid #dcdee2;
  align-items: center;
  box-shadow: 0 0 0px 3px transparent;
  font-size: 14px;
  color: #515a6e;
  padding: 0 8px;
  min-height: 30px;
}
.org-form-dev {
  // 报错设置
  .verifier-error {
    border-color: red;
  }
}
</style>